import { addPropertyControls, ControlType, Image } from "framer"
import { motion } from "framer-motion"
import { useState, useEffect, useRef } from "react"
import Cookies from "js-cookie"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

// Sun and moon icons from tabler-icons.io

const THEME_ATTRIBUTE = "framestack-theme"
const COOKIE_NAME = "framestack-theme"
const STYLE_ID = "framestack-theme-toggle"
const SIGNAL_NAME = "framestack-theme-changed"

const THEMES = ["light", "dark", "deviceTheme"]

const useThemeStore = createStore("deviceTheme")

function extractBodySection(inputString) {
    const searchStr = "body{"
    const startIndex = inputString.indexOf(searchStr)

    if (startIndex !== -1) {
        const endIndex = inputString.indexOf("}", startIndex + searchStr.length)

        if (endIndex !== -1) {
            const bodySection = inputString.substring(
                startIndex + searchStr.length,
                endIndex
            )
            return bodySection
        }
    }

    return null // Return null if "body{" or "}" is not found
}

function makeBorderStyle(border) {
    return border
        ? {
              borderWidth: border.widthIsMixed
                  ? `${border.widthTop}px ${border.widthRight}px ${border.widthBottom}px ${border.widthLeft}px`
                  : `${border.width}px`,
              borderStyle: border.style,
              borderColor: border.color,
          }
        : []
}

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function ThemeToggle(props) {
    const { toggle, dropdown, dropdownOptions, icons, transition } = props

    let isPreview = false
    let initialTheme = props.defaultTheme

    if (typeof document !== "undefined") {
        if (document.body.hasAttribute("data-framer-theme")) {
            if (initialTheme == "deviceTheme") {
                initialTheme = document.body.getAttribute("data-framer-theme")
            }
            isPreview = true
        } else if (document.body.hasAttribute(THEME_ATTRIBUTE)) {
            initialTheme = document.body.getAttribute(THEME_ATTRIBUTE)
        } else if (props.saveTheme) {
            const storedTheme = Cookies.get(COOKIE_NAME)
            if (THEMES.includes(storedTheme)) {
                initialTheme = storedTheme
            }
        }
    }

    let deviceTheme = "light"
    if (isPreview && document.body.hasAttribute("data-framer-theme")) {
        deviceTheme = document.body.getAttribute("data-framer-theme")
    } else if (typeof window != "undefined" && window?.matchMedia) {
        deviceTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light"
    }

    const [theme, setTheme] = useThemeStore()
    const [activeTheme, setActiveTheme] = useState(
        initialTheme == "deviceTheme" ? deviceTheme : initialTheme
    ) // light or dark
    const [originalPreviewTheme, setOriginalPreviewTheme] =
        useState(initialTheme)

    const themeRef = useRef(theme)
    const selectRef = useRef(null)

    function changeTheme(newTheme) {
        let newActiveTheme = newTheme
        if (newTheme == "deviceTheme") {
            if (isPreview) {
                newActiveTheme = originalPreviewTheme
            } else {
                newActiveTheme = deviceTheme
            }
        }

        setTheme(newTheme)
        setActiveTheme(newActiveTheme)

        if (isPreview) {
            document.body.setAttribute("data-framer-theme", newActiveTheme)
        }
        document.body.setAttribute(THEME_ATTRIBUTE, newTheme)
        document.documentElement.setAttribute(THEME_ATTRIBUTE, newTheme)

        if (props.saveTheme) {
            Cookies.set(COOKIE_NAME, newTheme, { expires: 365 }) // Expires in 1 year
        }
        window.dispatchEvent(new CustomEvent(SIGNAL_NAME))
    }

    function toggleTheme() {
        const newActiveTheme = activeTheme == "light" ? "dark" : "light"
        if (props.resetToDeviceTheme) {
            changeTheme(
                newActiveTheme == deviceTheme ? "deviceTheme" : newActiveTheme
            )
        } else {
            changeTheme(newActiveTheme)
        }
    }

    function onSelectChange() {
        if (selectRef.current) {
            changeTheme(THEMES[selectRef.current.selectedIndex])
        }
    }

    const handleThemeChange = (event) => {
        if (themeRef.current == "deviceTheme") {
            setActiveTheme(event.matches ? "dark" : "light")
        }
    }

    useEffect(() => {
        themeRef.current = theme // Update the ref whenever the theme state changes

        if (theme == "deviceTheme") {
            if (isPreview) {
                setActiveTheme(originalPreviewTheme)
            } else {
                setActiveTheme(deviceTheme)
            }
        } else {
            setActiveTheme(theme)
        }
    }, [theme])

    useEffect(() => {
        if (initialTheme != theme) {
            changeTheme(initialTheme)
        }
        // const themeAttr = document.body.getAttribute(THEME_ATTRIBUTE)
        // if (themeAttr) {
        //     changeTheme(themeAttr)
        // }

        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
        mediaQuery.addEventListener("change", handleThemeChange)

        if (!isPreview) {
            let generateStyle = true

            // Check if <head> already has theme attribute
            const headElement = document.querySelector("head")
            if (headElement) {
                if (headElement.querySelector(`style#${STYLE_ID}`)) {
                    generateStyle = false
                }
            }

            // Generate <style> with color styles
            if (generateStyle) {
                let style = document.querySelector(
                    "style[data-framer-css-ssr-minified]"
                )

                if (style && style?.textContent) {
                    const styleText = style.textContent
                    const darkLocation = styleText.indexOf(
                        "@media (prefers-color-scheme: dark){body{--token"
                    )

                    if (darkLocation !== -1) {
                        var styleElement = document.createElement("style")
                        styleElement.id = STYLE_ID
                        styleElement.textContent = `body[${THEME_ATTRIBUTE}="light"] {${extractBodySection(
                            styleText
                        )}} body[${THEME_ATTRIBUTE}="dark"] {${extractBodySection(
                            styleText.substring(darkLocation)
                        )}} ${
                            props.setColorScheme
                                ? `html {color-scheme: light dark; } html[${THEME_ATTRIBUTE}="light"] { color-scheme: light; } html[${THEME_ATTRIBUTE}="dark"] { color-scheme: dark; } `
                                : ""
                        }`
                        document.head.appendChild(styleElement)
                    }
                }
            }
        }

        return () => mediaQuery.removeEventListener("change", handleThemeChange)
    }, [])

    switch (props.componentStyle) {
        case "toggle":
            const { height, padding, shadow } = toggle
            return (
                <motion.div
                    onClick={toggleTheme}
                    style={{
                        position: "relative",
                        minHeight: height,
                        minWidth: height * 2 - padding * 2,
                        backgroundColor: toggle.fill,
                        padding: padding,
                        borderRadius: toggle.radius,
                        color: icons?.color,
                        cursor: "pointer",
                        ...props.style,
                    }}
                    layout={false}
                    whileHover={{
                        color: icons?.hoverColor || icons?.color,
                    }}
                    initial={false}
                    transition={transition}
                >
                    {props.border && (
                        <div
                            style={{
                                position: "absolute",
                                inset: 0,
                                boxSizing: "border-box",
                                borderRadius: toggle.radius,
                                ...makeBorderStyle(props.border),
                            }}
                        />
                    )}
                    <motion.div
                        style={{
                            position: "absolute",
                            height: height - padding * 2,
                            aspectRatio: 1,
                            [activeTheme == "light" ? "left" : "right"]:
                                padding,
                            backgroundColor: toggle.switch,
                            borderRadius: toggle.radius - padding,
                            boxShadow: shadow
                                ? `${shadow.x}px ${shadow.y}px ${shadow.blur}px ${shadow.spread}px ${shadow.color}`
                                : "none",
                            boxSizing: "border-box",
                            ...makeBorderStyle(toggle.switchBorder),
                        }}
                        layout
                        initial={false}
                        transition={transition}
                    >
                        {icons && (
                            <div
                                style={{
                                    position: "absolute",
                                    inset: 0,
                                }}
                            >
                                <Icon
                                    theme={activeTheme}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                    {...icons}
                                />
                            </div>
                        )}
                    </motion.div>
                </motion.div>
            )
        case "dropdown":
            const { showDeviceTheme } = dropdownOptions
            const themeTitles = {
                light: dropdownOptions.lightText,
                dark: dropdownOptions.darkText,
                deviceTheme: dropdownOptions.deviceThemeText,
            }
            return (
                <motion.div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: dropdown.gap,
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: dropdown.fill,
                        color: dropdown.fontColor,
                        borderRadius: dropdown.radiusIsMixed
                            ? `${dropdown.radiusTopLeft}px ${dropdown.radiusTopRight}px ${dropdown.radiusBottomRight}px ${dropdown.radiusBottomLeft}px`
                            : `${dropdown.radius}px`,
                        padding: dropdown.paddingIsMixed
                            ? `${dropdown.paddingTopLeft}px ${dropdown.paddingTopRight}px ${dropdown.paddingBottomRight}px ${dropdown.paddingBottomLeft}px`
                            : `${dropdown.padding}px`,
                        userSelect: "none",
                        boxSizing: "border-box",
                        cursor: "pointer",
                        "--icon-color": icons?.color,
                        ...makeBorderStyle(props.border),
                        ...dropdown.font,
                        ...props.style,
                    }}
                    whileHover={{
                        color: dropdown.fontColorHover || dropdown.fontColor,
                        "--icon-color": icons?.hoverColor || icons?.color,
                    }}
                    initial={false}
                    transition={transition}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: dropdown.gap,
                            textWrap:
                                props.style?.width == "100%"
                                    ? "wrap"
                                    : "nowrap",
                        }}
                    >
                        {icons && (
                            <div style={{ display: "contents" }}>
                                <Icon
                                    theme={activeTheme}
                                    {...props.icons}
                                    style={{ color: "var(--icon-color)" }}
                                />
                            </div>
                        )}
                        {!showDeviceTheme && theme == "deviceTheme"
                            ? themeTitles[deviceTheme]
                            : themeTitles[theme]}
                    </div>
                    <div style={{ display: "contents", color: dropdown.arrow }}>
                        {dropdown.arrow && <Icon theme="arrow" size={18} />}
                    </div>
                    <select
                        ref={selectRef}
                        name="theme"
                        onChange={onSelectChange}
                        style={{ position: "absolute", inset: 0, opacity: 0 }}
                    >
                        <option
                            value="light"
                            selected={
                                theme == "light" ||
                                (!showDeviceTheme && deviceTheme == "light")
                            }
                        >
                            {themeTitles.light}
                        </option>
                        <option
                            value="dark"
                            selected={
                                theme == "dark" ||
                                (!showDeviceTheme && deviceTheme == "dark")
                            }
                        >
                            {themeTitles.dark}
                        </option>
                        {showDeviceTheme && (
                            <option
                                value="deviceTheme"
                                selected={theme == "deviceTheme"}
                            >
                                {themeTitles.deviceTheme}
                            </option>
                        )}
                    </select>
                </motion.div>
            )
        case "custom":
            return (
                <div onClick={toggleTheme}>
                    {activeTheme == "light"
                        ? props.customLight
                        : props.customDark}
                </div>
            )
    }
}

ThemeToggle.displayName = "Theme Toggle"

const borderControls = {
    color: {
        type: ControlType.Color,
        defaultValue: "#222",
    },
    width: {
        type: ControlType.FusedNumber,
        defaultValue: 1,
        toggleKey: "widthIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["widthTop", "widthRight", "widthBottom", "widthLeft"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
    },
    style: {
        type: ControlType.Enum,
        defaultValue: "solid",
        options: ["solid", "dashed", "dotted", "double"],
        optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
    },
}

const toggleShadowDefault = {
    color: "rgba(0, 0, 0, 0.2)",
    x: 0,
    y: 2,
    blur: 4,
    spread: 0,
}

const iconsDefault = {
    type: "default",
    color: "#333333",
    size: 18,
    opacity: 1,
}

addPropertyControls(ThemeToggle, {
    defaultTheme: {
        type: ControlType.Enum,
        defaultValue: "deviceTheme",
        options: ["deviceTheme", "light", "dark"],
        optionTitles: ["Device Theme", "Light", "Dark"],
        title: "Default",
    },
    saveTheme: {
        type: ControlType.Boolean,
        defaultValue: true,
        title: "Save Cookie",
    },
    componentStyle: {
        type: ControlType.Enum,
        defaultValue: "toggle",
        options: ["toggle", "dropdown", "custom"],
        optionTitles: ["Toggle", "Dropdown", "Custom"],
        title: "Style",
    },
    toggle: {
        type: ControlType.Object,
        buttonTitle: "Options",
        icon: "boolean",
        controls: {
            height: {
                type: ControlType.Number,
                defaultValue: 32,
                min: 1,
                step: 1,
            },
            fill: {
                type: ControlType.Color,
                defaultValue: "#EDEDED",
                optional: true,
            },
            switch: {
                type: ControlType.Color,
                defaultValue: "#FFF",
            },
            switchBorder: {
                type: ControlType.Object,
                optional: true,
                controls: borderControls,
            },
            shadow: {
                type: ControlType.Object,
                defaultValue: toggleShadowDefault,
                optional: true,
                controls: {
                    color: {
                        type: ControlType.Color,
                        defaultValue: toggleShadowDefault.color,
                    },
                    x: {
                        type: ControlType.Number,
                        defaultValue: toggleShadowDefault.x,
                        displayStepper: true,
                    },
                    y: {
                        type: ControlType.Number,
                        defaultValue: toggleShadowDefault.y,
                        displayStepper: true,
                    },
                    blur: {
                        type: ControlType.Number,
                        defaultValue: toggleShadowDefault.blur,
                        min: 0,
                        displayStepper: true,
                    },
                    spread: {
                        type: ControlType.Number,
                        defaultValue: toggleShadowDefault.spread,
                        displayStepper: true,
                    },
                },
            },
            padding: {
                type: ControlType.Number,
                defaultValue: 4,
                min: 0,
                step: 1,
                displayStepper: true,
            },
            radius: {
                type: ControlType.Number,
                defaultValue: 16,
                min: 0,
            },
        },
        hidden: (props) => props.componentStyle !== "toggle",
    },
    dropdown: {
        type: ControlType.Object,
        buttonTitle: "Options",
        controls: {
            fill: {
                type: ControlType.Color,
                defaultValue: "#EDEDED",
                optional: true,
            },
            fontColor: {
                type: ControlType.Color,
                defaultValue: "#000",
            },
            fontColorHover: {
                type: ControlType.Color,
                optional: true,
                title: "Hover Font Color",
            },
            font: {
                type: "font",
                controls: "extended",
                defaultFontType: "sans-serif",
                defaultValue: {
                    fontSize: 16,
                    lineHeight: 1,
                },
            },
            arrow: {
                type: ControlType.Color,
                defaultValue: "rgba(0, 0, 0, 0.5)",
                optional: true,
            },
            gap: {
                type: ControlType.Number,
                defaultValue: 8,
                min: 0,
                step: 1,
            },
            padding: {
                type: ControlType.FusedNumber,
                defaultValue: 8,
                toggleKey: "paddingIsMixed",
                toggleTitles: ["All", "Individual"],
                valueKeys: [
                    "paddingTopLeft",
                    "paddingTopRight",
                    "paddingBottomRight",
                    "paddingBottomLeft",
                ],
                valueLabels: ["T", "R", "B", "L"],
                min: 0,
            },
            radius: {
                type: ControlType.FusedNumber,
                defaultValue: 10,
                toggleKey: "radiusIsMixed",
                toggleTitles: ["All", "Individual"],
                valueKeys: [
                    "radiusTopLeft",
                    "radiusTopRight",
                    "radiusBottomRight",
                    "radiusBottomLeft",
                ],
                valueLabels: ["TL", "TR", "BR", "BL"],
                min: 0,
            },
        },
        hidden: (props) => props.componentStyle !== "dropdown",
    },
    dropdownOptions: {
        type: ControlType.Object,
        title: "Options",
        buttonTitle: "Options",
        // buttonTitle: "Options",
        controls: {
            showDeviceTheme: {
                type: ControlType.Boolean,
                defaultValue: true,
            },
            deviceThemeText: {
                type: ControlType.String,
                defaultValue: "Device Theme",
                hidden: (props) => !props.showDeviceTheme,
            },
            lightText: {
                type: ControlType.String,
                defaultValue: "Light",
            },
            darkText: {
                type: ControlType.String,
                defaultValue: "Dark",
            },
        },
        hidden: (props) => props.componentStyle !== "dropdown",
    },
    icons: {
        type: ControlType.Object,
        defaultValue: iconsDefault,
        optional: true,
        controls: {
            type: {
                type: ControlType.Enum,
                defaultValue: "default",
                options: ["default", "svg", "image"],
                optionTitles: ["Default", "SVG", "Image"],
                displaySegmentedControl: true,
                segmentedControlDirection: "vertical",
            },
            lightSvg: {
                type: ControlType.String,
                placeholder: "<svg></svg>",
                displayTextArea: true,
                title: "Light SVG",
                hidden: (props) => props.type != "svg",
            },
            darkSvg: {
                type: ControlType.String,
                placeholder: "<svg></svg>",
                displayTextArea: true,
                title: "Dark SVG",
                hidden: (props) => props.type != "svg",
            },
            lightImage: {
                type: ControlType.ResponsiveImage,
                title: "Light",
                hidden: (props) => props.type != "image",
            },
            darkImage: {
                type: ControlType.ResponsiveImage,
                title: "Dark",
                hidden: (props) => props.type != "image",
            },
            sizing: {
                type: ControlType.Enum,
                defaultValue: "cover",
                options: ["fill", "fit", "stretch"],
                optionTitles: ["Fill", "Fit", "Stretch"],
                hidden: (props) => props.type != "image",
            },
            color: {
                type: ControlType.Color,
                defaultValue: iconsDefault.color,
                hidden: (props) =>
                    props.type != "default" && props.type != "svg",
            },
            hoverColor: {
                type: ControlType.Color,
                optional: true,
                hidden: (props) =>
                    props.type != "default" && props.type != "svg",
            },
            size: {
                type: ControlType.Number,
                defaultValue: iconsDefault.size,
                min: 1,
                step: 1,
                displayStepper: true,
            },
            opacity: {
                type: ControlType.Number,
                defaultValue: 1,
                min: 0,
                max: 1,
                step: 0.01,
            },
        },
        hidden: (props) => props.componentStyle == "custom",
    },
    border: {
        type: ControlType.Object,
        optional: true,
        controls: borderControls,
        hidden: (props) => props.componentStyle == "custom",
    },
    customLight: {
        type: ControlType.ComponentInstance,
        title: "Light",
        hidden: (props) => props.componentStyle !== "custom",
    },
    customDark: {
        type: ControlType.ComponentInstance,
        title: "Dark",
        hidden: (props) => props.componentStyle !== "custom",
    },
    resetToDeviceTheme: {
        type: ControlType.Boolean,
        defaultValue: true,
        // description:
        //     "Uses the device theme when the toggle is switched to the current device theme.",
        hidden: (props) => props.componentStyle !== "toggle",
    },
    setColorScheme: {
        type: ControlType.Boolean,
        defaultValue: true,
        title: "Theme Scroll Bars",
    },
    transition: {
        type: ControlType.Transition,
        hidden: (props) => props.type == "custom",
    },
})

function Icon(props) {
    const {
        theme,
        type,
        size,
        lightSvg,
        darkSvg,
        lightImage,
        darkImage,
        sizing,
        opacity,
        style = {},
    } = props

    switch (type) {
        case "svg":
            if (
                (theme == "light" && !lightSvg.length) ||
                (theme == "dark" && !darkSvg.length)
            ) {
                return <div />
            }

            return (
                <>
                    <div
                        className="theme-toggle-icon"
                        style={{
                            width: size,
                            height: size,
                            opacity,
                            pointerEvents: "none",
                            ...style,
                        }}
                        dangerouslySetInnerHTML={{
                            __html: (theme == "light" ? lightSvg : darkSvg)
                                .replace(/width="(\d+)"/, `width="${size}"`)
                                .replace(/height="(\d+)"/, `width="${size}"`),
                        }}
                    />
                    <style>{`.theme-toggle-icon svg { display: block; }`}</style>
                </>
            )
        case "image":
            return (
                <Image
                    background={{
                        fit: sizing,
                        ...(theme == "light" ? lightImage : darkImage),
                    }}
                    style={{ opacity, width: size, height: size, ...style }}
                />
            )
        case "default":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ opacity, ...style }}
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    {theme == "light" ? (
                        [
                            <path
                                d="M12 19a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z"
                                strokeWidth="0"
                                fill="currentColor"
                            ></path>,
                            <path
                                d="M18.313 16.91l.094 .083l.7 .7a1 1 0 0 1 -1.32 1.497l-.094 -.083l-.7 -.7a1 1 0 0 1 1.218 -1.567l.102 .07z"
                                strokeWidth="0"
                                fill="currentColor"
                            ></path>,
                            <path
                                d="M7.007 16.993a1 1 0 0 1 .083 1.32l-.083 .094l-.7 .7a1 1 0 0 1 -1.497 -1.32l.083 -.094l.7 -.7a1 1 0 0 1 1.414 0z"
                                strokeWidth="0"
                                fill="currentColor"
                            ></path>,
                            <path
                                d="M4 11a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z"
                                strokeWidth="0"
                                fill="currentColor"
                            ></path>,
                            <path
                                d="M21 11a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z"
                                strokeWidth="0"
                                fill="currentColor"
                            ></path>,
                            <path
                                d="M6.213 4.81l.094 .083l.7 .7a1 1 0 0 1 -1.32 1.497l-.094 -.083l-.7 -.7a1 1 0 0 1 1.217 -1.567l.102 .07z"
                                strokeWidth="0"
                                fill="currentColor"
                            ></path>,
                            <path
                                d="M19.107 4.893a1 1 0 0 1 .083 1.32l-.083 .094l-.7 .7a1 1 0 0 1 -1.497 -1.32l.083 -.094l.7 -.7a1 1 0 0 1 1.414 0z"
                                strokeWidth="0"
                                fill="currentColor"
                            ></path>,
                            <path
                                d="M12 2a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z"
                                strokeWidth="0"
                                fill="currentColor"
                            ></path>,
                            <path
                                d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z"
                                strokeWidth="0"
                                fill="currentColor"
                            ></path>,
                        ]
                    ) : theme == "dark" ? (
                        <path
                            d="M12 1.992a10 10 0 1 0 9.236 13.838c.341 -.82 -.476 -1.644 -1.298 -1.31a6.5 6.5 0 0 1 -6.864 -10.787l.077 -.08c.551 -.63 .113 -1.653 -.758 -1.653h-.266l-.068 -.006l-.06 -.002z"
                            strokeWidth="0"
                            fill="currentColor"
                        ></path>
                    ) : (
                        // Arrow
                        <path d="M6 9l6 6l6 -6"></path>
                    )}
                </svg>
            )
    }
}
